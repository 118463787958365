import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`🌏🌍🌎 Translations`}</h1>
    <p>{`The following link is the source of truth regarding which languages the Polis interface has been translated into:
`}<a parentName="p" {...{
        "href": "https://github.com/compdemocracy/polis/tree/dev/client-participation/js/strings"
      }}>{`https://github.com/compdemocracy/polis/tree/dev/client-participation/js/strings`}</a></p>
    <p>{`These translations will automatically load if someone's browser settings are set to one of them. `}</p>
    <p>{`For up-to-the-moment information, visit the link above.
For convenience, you may browse the more readable list below which is subject to change without notice, and was last updated August 12, 2022.`}<br parentName="p"></br>{`
`}{`If you don't see the language you are interested in, or wish to improve a translation that has already been started, see `}<a parentName="p" {...{
        "href": "https://compdemocracy.org/community-contributed-translations/"
      }}>{`https://compdemocracy.org/community-contributed-translations/`}</a>{`. Please note, Romanian (ro) and Persian / Farsi (fa) are already in the queue to be added. `}</p>
    <p>{`cy - Welsh  `}</p>
    <p>{`da_dk - Danish (Denmark)  `}</p>
    <p>{`de_de - German (Germany)  `}</p>
    <p>{`en_us - English (US)  `}</p>
    <p>{`es_la - Spanish (Latin America)  `}</p>
    <p>{`fr - French  `}</p>
    <p>{`gr - Greek  `}</p>
    <p>{`he - Hebrew  `}</p>
    <p>{`hr - Croatian  `}</p>
    <p>{`it - Italian  `}</p>
    <p>{`ja - Japanese  `}</p>
    <p>{`nl - Dutch (Flemish)  `}</p>
    <p>{`pt_tl - Portuguese  `}</p>
    <p>{`pt_br - Portuguese (Brasil)  `}</p>
    <p>{`ru - Russian  `}</p>
    <p>{`sk - Slovak  `}</p>
    <p>{`tdt - Timor Leste  `}</p>
    <p>{`uk - Ukrainian  `}</p>
    <p>{`zh_hans - Chinese (Han, simplified)  `}</p>
    <p>{`zh_hant - Chinese (Han, traditional)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      